import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import TargetAudienceComponent from "../../components/pages/main/target-audience"
import AboutComponent from "../../components/pages/main/about"
import ReviewComponent from "../../components/pages/main/review"
import FeaturesComponent from "../../components/pages/main/features"
import PricingComponent from "../../components/pages/main/pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import EmailChecklist from "../../components/pages/main/email-checklist"
import CustomersComponent from "../../components/pages/main/customers"
import FreeSummer from "../../components/pages/main/free-summer"

const CulinarySchoolPage = (props) => (
  <Layout headerColor={"#677C94"} page={"landing_culinaryschool"}>
    <SEO title="CRM-система для кулинарных школ" keywords={[`crm-система`, `учет клиентов`]} url={"https://paraplancrm.ru" + props.location.pathname}/>
    <HeroFeaturePage
      name={"landing_culinaryschool"}
      title='CRM-система для кулинарных школ'
      btnGreen={true}
      description='Учет клиентов, посещений и оплат. Планирование задач и рабочей нагрузки. Все процессы в одном месте.'
      color='#677C94'
      image={require("../../images/cover/landing_culinaryschool.png")}/>
    <TargetAudienceComponent/>
    <FreeSummer/>
    <AboutComponent
      title='Простой инструмент для ведения бизнеса'
      description='Параплан был создан с учетом специфики бизнеса детских и учебных центров. Здесь есть все, что вам нужно. Моментальный учет оплат и учет посещаемости, удобное добавление и удаление учеников, а также Email и SMS рассылки в любое время, с любого устройства.'
      image={require("../../images/screenshot/main_01@2x.png")}
      imageWidth={739}
      imageHeight={376}
      color='#E65D49'/>
    <ReviewComponent author='Детский клуб «Волшебники»'>
      С системой Параплан мы тратим в 3 раза меньше времени на учет платежей, составление расписания и регистрацию новых
      учеников. Все бумажные журналы остались в прошлом. Теперь мы уделяем больше времени развитию наших обучающих
      программ, а не ручному подсчету заработной платы.
    </ReviewComponent>
    <FeaturesComponent title='Ваш бизнес способен на большее вместе с&nbsp;Параплан:'/>
    <EmailChecklist customerType={""}/>
    <CustomersComponent/>
    <PricingComponent title='Тарифы'/>
    <ContactFormComponent/>
  </Layout>
)

export default CulinarySchoolPage

import React from "react"
import styles from "./free-summer.module.scss"
import ScrollAnimation from "react-animate-on-scroll"
import { openOverlay } from "../../../../utils/overlay"

const FreeSummer = ({topInd}) => (
  <div className={styles.freeSummer} style={{ marginTop: topInd + 'px'}}><span id="summer"></span>
    <div className={`container ${styles.freeSummerContainer}`}>
      <div className={styles.wrapper}>
        <div className={styles.action}>АКЦИЯ</div>
        <div className={styles.free}>БЕСПЛАТНОЕ ЛЕТО</div>
        <div className={styles.text}><strong>до 90 дней</strong> пользования системой* и Личными кабинетами учеников в <strong>ПОДАРОК </strong>
          новым клиентам</div>
        <button className={`${styles.cta}`}  onClick={() => openOverlay('free-summer', "action", "trial")}>
          Зарегистрироваться
        </button>
        <div className={styles.option}>* Количество бесплатных дней может меняться в зависимости
          от приобретенной подписки. Срок акции ограничен.</div>
        <ScrollAnimation animateIn={"animate-shake"} duration={1}>
          <div className={styles.arrow}/>
        </ScrollAnimation>
      </div>
    </div>
  </div>
)

export default FreeSummer
